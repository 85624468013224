<!--
* @description:
* @fileName single-input.vue
* @author hvv
* @date 2022/08/23 14:29:18
!-->
<template>
  <div class="radio-inputBox">
    <div class="form-outlineBox">
      <img
        src="https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/common-default-picture/common-no-data.png"
        alt=""
        style="width: 200px; height: 100px"
      />
      <div>定制模块不支持修改</div>
    </div>
    <div class="form-config">
      <div class="tripName">[{{res.name}}]</div>
      <div></div>
      <div class="config-right-plane">
        <el-checkbox v-model="res.required" label="必填" size="small" />
        <el-checkbox v-model="res.isSearch" label="隔离字段" size="small" />
        <el-icon class="icon" @click="deleteFormItem()">
          <DeleteFilled />
        </el-icon>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    defineComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    computed,
    toRefs,
  } from 'vue'
  export default defineComponent({
    name: 'radio-input.vue',
    props: {
      res: {
        type: Object,
        default: {},
      },
      index: {
        type: Number,
        default: 0,
      },
    },

    emits: ['delete-form-item'],
    setup(props, { emit }) {
      const state = reactive({
        ruleOptions: [
          {
            label: '手机号',
            value: 'phoneReg',
          },
          {
            label: '邮箱',
            value: 'emailReg',
          },
        ],
        isAddOther: false,
      })

      const deleteFormItem = () => {
        emit('delete-form-item', props.index)
      }

      state.isAddOther = computed(() => {
        return props.res.child.filter((v) => v.type == 'other').length > 0
          ? true
          : false
      })

      const addOther = () => {
        props.res.child.push({ type: 'other', label: '其他' })
      }

      const deleteChildOption = (index) => {
        props.res.child.splice(index, 1)
      }

      const addOption = () => {
        props.res.child.push(Object.assign({}, { type: 'normal', label: '' }))
      }

      return {
        ...toRefs(state),
        deleteFormItem,
        addOther,
        deleteChildOption,
        addOption,
      }
    },
  })
</script>
<style lang="scss" scoped>
  .radio-inputBox {
    width: 100%;
    .form-config {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 10px 40px;
      margin-top: 10px;
      border-top: 1px solid #e0e0e0;
      position: relative;
      .tripName {
        position: absolute;
        top: -150px;
        right: 30px;
        font-size: 20px;
        font-weight: bold;
        color: #eb4b17;
      }
      .config-right-plane {
        .icon {
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }
    .form-outlineBox {
      width: 100%;
      padding: 30px 20px 0px 20px;
      display: flex;
      align-items: center;
      flex-direction: column;
      .form-index {
        display: flex;
        min-width: 40px;
        margin-right: 5px;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        color: #1f1f1f;
        .requireImg {
          width: 20px;
          height: 20px;
          margin-top: 3px;
        }
      }
      .form-content {
        width: calc(100% - 40px);
        .form-question {
          margin-top: 20px;
          .radio-label {
            display: flex;
            .otherWord {
              display: inline-block;
              width: 50px;
              padding-top: 5px;
              font-size: 16px;
              color: #333;
            }
            .icon {
              margin-top: 3px;
              margin-right: 10px;
              font-size: 24px;
              cursor: pointer;
            }
          }
          .addLabel {
            display: flex;
            margin-top: 10px;
            .addIcon {
              margin-right: 10px;
              font-size: 24px;
            }
            .addWord {
              margin-right: 15px;
              font-size: 18px;
              color: #333;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
</style>
